import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const w11GB01Schnuppern = () => (
  <Layout>
    <SEO title="w11GB01Schnuppern" />

    <h1 id="ueberschrift">Gemeinsam Backen</h1>
    <h2>Schnuppertreff für Interessierte &emsp;  ©&nbsp;2003</h2>

      <p>
        Kontakt:&ensp;
        <a href="mailto:iarna@bastet-project.de?subject=Anfrage zu w11GB01Schnuppern&amp;body=Bitte senden Sie so viele Daten wie möglich (z.B.: gewünschter Ort, Tag, Anzahl der Teilnehmer, Altersgruppe, etc.)." title="per E-Mail kontaktieren" style={{ fontSize: "1em" }}>iarna@bastet-project.de</a>
      </p>

      <p className="poem" style={{textDecoration: "underline"}}>
        Kuchenfolter
      </p>

  <div className="container">
    <div className="row">
      <div  style={{ width: `23em`, margin: "auto"}}>
      <p className="poem">
        Es duftet gut das ganze Haus<br />
        Da läuft im Mund das Wasser<br />
        Und freuen tun sich Mann und Maus<br />
        Doch gibt es 'nen Aufpasser.
      </p>
      </div>
      <div  style={{ width: `25em`, margin: "auto"}}>
      <p className="poem">
        Der mahnt: "Der Kuchen ist noch heiß!"<br />
        Warum soll man denn leiden?!<br />
        Ein Backwerk ist, wie jeder weiß,<br />
        auch warm ganz gut zu schneiden!
      </p>
      </div>
    </div>
  </div>
        BC &emsp; ©&nbsp;1982

    <p>---</p>

      <p>
        Vom "Amerikaner mit Zuckerguss" bis zur "Zauberwaldtorte mit Buttercremeverzierungen" (RS&nbsp;©1989):<br />
        Alles macht viel mehr Spaß, wenn es zusammen mit anderen erforscht und gekostet werden kann.
        Gemeinsam schnuppern wir in der Küche Ihrer Wahl in die unterschiedlichsten Rezepte und profitieren von
        den verschiedenen guten sowie weniger schönen Erfahrungen. Genießen Sie die Zeit
        beim geselligen Zubereiten und Vernichten der Kreationen welche oft
        mit lustigen und interessanten Anekdoten gewürzt sind.
      </p>
      <p>
        Auf Grund der aktuellen Situation kann dieser Kurs nur "außer Haus" angeboten werden.
      </p>

      <p style={{fontStyle: "italic", textDecoration: "underline"}}>
        Wissensaustausch &amp; Ideenfindung
      </p>
      <p>
        für alle Altersgruppen<br/>
        Platzbedarf: 1 Sitz- oder Stehplatz pro Teilnehmer + mind. 1 Sitz- oder Stehplatz extra<br/>
        Materialbedarf: + je nach Backwerk +
      </p>
      <p>
        Privatstunden möglich<br/>
        Platzbedarf: 2 Sitzplätze und etwas Freiraum
      </p>
      <p>
        Voraussetzung: + Grundkenntnisse im Umgang mit diversen Küchenutensilien +
      </p>

    <Link to="/fdml_1a_aktuelles-klartext/" title="Detaillierte Informationen zum Bereich Veranstaltungen &amp; mehr" >Aktuelle Termine</Link>
      <p></p>
  </Layout>
              )

              export default w11GB01Schnuppern
